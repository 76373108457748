// Custom.scss
// Option B: Include parts of CoreUI
// $form-select-border-radius-lg: 1rem;
// $form-select-bg: #fff;

// // 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
// @import "../../../node_modules/@coreui/coreui/scss/functions";

// // 2. Include any default variable overrides here
// $form-select-border-radius-lg: 1rem;
// $form-select-bg: #fff;

// // 3. Include remainder of required CoreUI stylesheets
// @import "../../../node_modules/@coreui/coreui/scss/variables";

// // 4. Include any default map overrides here

// // 5. Include remainder of required parts
// @import "../../../node_modules/@coreui/coreui/scss/maps";
// @import "../../../node_modules/@coreui/coreui/scss/mixins";
// @import "../../../node_modules/@coreui/coreui/scss/root";

// // 6. Optionally include any other parts as needed
// @import "../../../node_modules/@coreui/coreui/scss/utilities";
// @import "../../../node_modules/@coreui/coreui/scss/reboot";
// @import "../../../node_modules/@coreui/coreui/scss/type";
// @import "../../../node_modules/@coreui/coreui/scss/images";
// @import "../../../node_modules/@coreui/coreui/scss/containers";
// @import "../../../node_modules/@coreui/coreui/scss/grid";
// @import "../../../node_modules/@coreui/coreui/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
// @import "../../../node_modules/@coreui/coreui/scss/utilities/api";

// 8. Add additional custom code here

// @import "../../../node_modules/@coreui/coreui/scss/forms/_form-select.scss";
$form-select-border-radius-lg: 1rem !important;
// $form-select-bg: #8e6d6d !important;
@import "../../../node_modules/@coreui/coreui/scss/coreui";


body {
    background-color: #ffffff00;;    
}

.full-size-button {
    font-size: 1.15rem;
    width: 100%;
}

.message-button {
    cursor: pointer; 
    margin: 2px;
    border-radius: 7px;
    height: 40px;
    // backgroundColor: grey;
    border: 0;
    color: white;
    font-size: 0.80rem;
    font-weight: 600;
    
    
    // display: flex;
    // font-size: 150%;
    // border-radius: 0.25rem;
    padding: 0 1%;
    // margin: 1%;
    background-color: #29B350;
    // background-color: white;
    // color: #f37863;
    font-size: 100%;
    // color: white;
    border: none;
    // width: 20%;
    text-align: center;
    justify-content: center;
}

.message-button:hover {
    // background-color: rgba(255, 84, 84, 0.714);
    background-color: #076923;
    // background-color: #dabdb8;
    // color: #d53b20;
    color: white;
    cursor: pointer;
}

.message-button:visited {
    background-color: #a5aea6;
    color: white;
}

.file-download-button {
    background-color: rgba(255, 84, 84, 0.714);
    color: white;
    cursor: pointer;
    height: fit-content;
    contain: paint;
    width: fit-content;
}

.file-download-button:hover {
    background-color: rgb(68, 206, 181);
    color: white;
    cursor: pointer;
}

.file-download-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // width: min-content;
    height: fit-content;
}

.file-download-container svg {
    height: 60px;
    width: 60px;
}

.file-download-caption {
    vertical-align: middle;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0%;
}