.text-bubble {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 4px 10px;
    width: 80%;
    align-self: flex-end;
    // text-align: center;
    font-size: 15px;
    color: white;
    background: #e4531f;
    border-radius: 15px 15px 0px 15px;
    font-weight: 600;
}

.message-bubble-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    // width: min-content;
    height: fit-content;
}

.message-bubble-button {
    cursor: pointer; 
    margin: 2px;
    border-radius: 7px;
    // height: 40px;
    // backgroundColor: grey;
    border: none;
    color: white;
    font-size: 0.80rem;
    font-weight: 600;
    min-width: 120px;
    
    
    // display: flex;
    // font-size: 150%;
    // border-radius: 0.25rem;
    padding: 1vh 3vw;
    // margin: 1%;
    // background-color: #e4531f;
    background: linear-gradient(145deg, #f47035, #e4531f); 
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3), /* Outer shadow for depth */
                inset -1px -1px 2px rgba(255, 255, 255, 0.2), /* Inner light for an inset effect */
                inset 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease;
    // background-color: white;
    // color: #f37863;
    font-size: 100%;
    // color: white;
    border: none;
    // width: 20%;
    text-align: center;
    justify-content: center;
}

.message-bubble-button:hover {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3), /* Smaller shadow on hover */
    inset 0px 0px 1px rgba(255, 255, 255, 0.1), /* Subtle inner light */
    inset 0px 0px 1px rgba(0, 0, 0, 0.2); /* Subtle inner dark */
    transform: translateY(-1px); /* Slight lift on hover */
}

.message-bubble-button:active {
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3); /* Pressed-in effect */
    transform: translateY(1px); /* Button appears pressed down */
}