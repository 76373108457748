.car-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 3px;
    /* height: 95%; */
    /* padding-bottom: 5vh; */
    /* padding-right: 5vw; */
    overflow: hidden;
    contain: content;
}

.car-container img {
    height: 100%;
    width: 100%;
//    border-top-left-radius: 10px;
   border-top-right-radius: 10px;
}

.car-next {
    display: flex;
    opacity: 0.9;
    position:absolute;
    flex-direction: column;
    top: 50%;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-content: center;
    border-radius: 100%;
    border-style:hidden;
    overflow: hidden;
}

.car-next-right {
    right: 0%;
}

.car-next-left {
    left: 0%;
}

/* LinkButton.css */

.car-link {
    text-decoration: none; /* Removes underline */
}

.car-button {
    background-color: #007BFF; /* Primary blue */
    opacity: 0.7;
    width: 100%; /* Full width */
    color: #FFF; /* White text color */
    padding: 12px 20px;
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Hand cursor on hover */
    font-size: 16px; /* Text size */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    outline: none; /* Remove default outline */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Slight shadow for depth */
}

.car-button:hover {
    background-color: #0056b3; /* Darker shade of blue on hover */
}

.car-button:active {
    background-color: #004085; /* Even darker shade for active (clicked) state */
}
