
$color-primary: #f0f0f0;
// $color-primary-light: #29B350;
// $color-primary-light: #32d5bd;
$color-primary-light: #e4531f;
$color-primary-dark: #e4531f;
// $color-primary-dark: #069f88db;

$color-secondary: #91abec;
$color-secondary-light: #a1b7ed;
$color-secondary-dark: #274693;

// Material text colors
// http://marcodiiga.github.io/rgba-to-rgb-conversion
// Hex values are calculated based on white background 
// If you change background color rgb color will change, that is why its better to use rgba with opacity!
$color-text: rgba(0, 0, 0, 0.87); // #212121
// $color-text: rgba(255, 255, 255, 0.87); // #212121
$color-text-medium: rgba(255, 255, 255, 0.60); // #212121
$color-text-disabled: rgba(255, 255, 255, 0.38); // #212121
// $color-text-medium: rgba(0, 0, 0, .60); // #666666
// $color-text-disabled: rgba(0, 0, 0, .38); // #9e9e9e
$color-text-inverted: invert($color-text); 

// Base colors
//
// It's not recommended to change this colors because white is white and black is... black :)
$color-white: white;
// $color-black: #ffffff;

$default-bg-color: $color-white;

// Element colors
// //

// $default-border-color: rgba-to-rgb(rgba(scale-color($color-primary-dark, $saturation: -55%), 0.4), $default-bg-color);

// // State colors
// //

// $color-hover: $color-text;
// $color-bg-hover: rgba-to-rgb(rgba($color-primary-dark, 0.08), $default-bg-color);
// $color-focus: $color-text;
// $color-bg-focus: rgba-to-rgb(rgba($color-primary-dark, 0.24), $color-primary-light);
// $color-selected: darken($color-primary-dark, 25%);
// $color-bg-selected: $color-bg-focus;
// $color-activated: darken($color-primary-dark, 25%) ;
// $color-bg-activated: $color-bg-focus;
// $color-active: $color-primary-dark;
// $color-bg-active: $color-bg-activated;
// $color-disabled: $color-text-disabled;
// $color-bg-disabled: rgba(0, 0, 0, .38);

.cs-conversation-header__user-name {
  color: #ffffff !important;
  // give a light drop shadow
  // text-shadow: 0px 0px 2px #806f25;
}

.cs-conversation-header__info {
  color: #ffffff !important;
  // give a light drop shadow
  // text-shadow: 0px 0px 2px #806f25;
}

.cs-message__image-content img{
  width:100%;
  height:100%;
  /* width: fit-content; */
  /* object-fit: contain; */
}

.cs-message--outgoing .cs-message__content {
  color: white !important;
  font-weight: 500;
}

.cs-message-input__content-editor[data-placeholder]:empty:before {
  color: #6eaad7d7 !important;
}

.cs-message-input__content-editor {
  -webkit-user-modify: read-write-plaintext-only !important;
}

.App {
  /* text-align: center; */
  /* max-width: 1000px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
  padding-bottom: 1vh;
  padding-right: 1vw;
  padding-left: 2vw;
  contain: content;
  overflow: hidden;
  opacity: 0.98;
}

.bubble-container img{
  // border-radius: 100%;
  height: 100%;
}

.chat-window-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 300px;
  contain: content;
}


.bubble-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 2vw;
  /* height: 10%; */
  min-height: 2%;
}

.bubble-div {
  width: 120px;
  height: 120px;
}

.bubble-div img {
  margin-left: -30px;
}

.bubble-container-open {
  // display: none;
}

.bubble-container-open .bubble-div img.bounce {
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  20% {
      transform: translateY(-40px); /* Big bounce */
  }
  40% {
      transform: translateY(0);
  }
  60% {
      transform: translateY(-15px); /* Small bounce */
  }
  80% {
      transform: translateY(0);
  }
}

.bubble-container-close .bubble-div img.walk {
  animation: walk 10s infinite;
}

@keyframes walk {
  0% {
    transform: translateX(0) scaleX(1); /* Initially facing right, no flip needed */
  }
  5% {
    transform: translateX(0) scaleX(-1); /* Quickly flip to face left before moving */
  }
  50% {
    transform: translateX(-40lvw) scaleX(-1); /* Walk left while facing left */
  }
  55% {
    transform: translateX(-40lvw) scaleX(1); /* After reaching the left side, quickly flip to face right */
  }
  100% {
    transform: translateX(0) scaleX(1); /* Walk back to the original position, facing right */
  }
}


.bubble-container-close {
  // display: none;
}

/* Base class for the chat popup overlay */
.chat-popup-overlay {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  padding-right: 2vw;
  padding-bottom: 0.1vh;
  transition: transform 0.5s ease-out, opacity 0.5s ease;
  /* Initially hidden and shifted */
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
}

/* Class when the chat popup is open */
.chat-popup-overlay-open {
  transform: translateX(0); /* Move into view */
  opacity: 1;
  visibility: visible;
}

.chat-popup-overlay-close {
  display: none;
}

// .chat-popup-overlay-open {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-end;
//   align-content: flex-end;
//   padding-right: 2vw;
//   padding-bottom: 1vh;
//   //shift left 30px
//   transform: translateX(-50px);
//   // animate the entry of this div
//   animation: slide-in 1s ease-in-out;
// }

.chat-popup-overlay-bubble {
  margin: 0;
  padding: 4px 10px;
  // text-align: center;
  font-size: 15px;
  color: white;
  background: #e4531f;
  border-radius: 15px 15px 0px 15px;
  font-weight: 600;
  width: 50%;
  position: relative;
  right: -45%;
}

.chat-popup {
  /* flex-grow: 100; */
  display: none;
  flex-direction: column;
  width: 100%;
  height: 88%;
  box-shadow:  0 0px 10px 5px rgba(0, 0,0, 0.12);
  /* position: fixed; */
  /* bottom: 0;
  right: 15px; */
  overflow: hidden;
  border: 0px solid #f1f1f1;
  border-radius: 7px;
  z-index: 9;
  margin-bottom: 1vh;
  contain: content;
}

.open{
  display: flex;
}

.powered-by {
  background-color: $default-bg-color;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 2%;
  align-content: center;
  text-align: center;
  padding: 0 2%;
  /* contain: content; */
}
.powered-by p {
  margin: 0;
  padding: 0;
  font-size: 0.75em;
  color: darkgray;
  font-weight: 300;
}
a.einbot-com-link img {
  height: 100%;
  margin-left: 2vw;
  // border-radius: 30%;
  /* width: auto; */
}


.einbot-com-link {
  text-decoration: none;
  color: #000000;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-content: center;
  text-align: center;
  padding: 0 0;;
}

@media not all and (min-width: 500px) and (max-width: 500px) {
  .App {
    /* text-align: center; */
    /* max-width: 1000px; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    padding-bottom: 0%;
    padding-right: 0vw;
    padding-left: 0%;
    contain: content;
    overflow: hidden;
    opacity: 0.98;
  }
  
  
  .bubble-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* height: 10%; */
    min-height: 2%;
  }

  .bubble-container-close {
    display: none;
  }
  
  .bubble-div {
    width: 100px;
    height: 100px;
    // overflow: hidden;
  }

  .bubble-div img {
    margin-left: -30px;
  }

  .bounce {
    animation: bounce 3s infinite;
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    20% {
        transform: translateY(-40px); /* Big bounce */
    }
    40% {
        transform: translateY(0);
    }
    60% {
        transform: translateY(-15px); /* Small bounce */
    }
    80% {
        transform: translateY(0);
    }
}
  
  .chat-popup {
    width: 100%;
    height: 100%;
    /* position: fixed; */
    /* bottom: 0;
    right: 15px; */
    border: 1px solid #f1f1f1;
    border-radius: 0px;
    z-index: 9;
    margin-bottom: 0;
    contain: content;
    // overscroll-behavior: contain;
  }
}


// .scrollbar-container {
//   overflow: auto !important;
//   overscroll-behavior-y: contain !important;
// }

/* Add styles to the form container */
/* .form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 20px;
}

.form-container textarea {
  width: 100%;
  margin: 5px 0 5px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  height: 100%;
  border-radius: 20px;
}

.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

.form-container .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
  border-radius: 20px;
}

.form-container .cancel {
  background-color: red;
}

.form-container .btn:hover, .open-button:hover {
  opacity: 1;
} */


img.custom_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

// .cs-message--outgoing .cs-message__content {
//   background-color: #aa0499;
// }

// $message-content-outgoing-color: #ffffff;
// $message-content-outgoing-bg-color: #1f62da;
// $message-content-incoming-color: #000000;
// $message-content-incoming-bg-color: #e0dcdf;
// $message-content-bg-color: #aa0499;

@import '@chatscope/chat-ui-kit-styles/themes/default/main.scss';
